export const environment = {
  production: true,
  apiEndpoint: '/api',
  serverApiEndpoint: 'https://dev.fantamanajer.it',
  matchdaysCount: 38,
  benchwarmersCount: 7,
  gaCode: 'UA-3016148-1',
  vapidPublicKey:
    'BEtTz3mWJt9vnMu759pONVf-KeKBv2isIgpfuCgpm_cxqBTwwUyS_eI6Dx7tKuutl0DzgYARKG6vuhfAszr5JBw',
};
